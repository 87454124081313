<template>
  <v-dialog v-model="dialog" width="600px" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        text
        color="#071C87"
      >
        {{ $t('navitationMenu.apply') }}</v-btn
      >
    </template>
    <v-card>
      <v-card-title>
        {{ $t('confirm.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('confirm.subtitle') }}
      </v-card-subtitle>
      <v-card-text>
        <v-row justify="center">
          <v-col class="text-center" cols="11">
              <v-avatar size="150">
                <img
                  height="90px"
                  width="90px"
                  src="@/assets/Iconos/Logo_ GROBE_.svg"
                />
              </v-avatar>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pb-3">
        <v-row justify="end">
          <v-col xl="6" lg="6" md="6" sm="11" cols="11">
            <v-btn @click="aplicarCambios()" block color="info">
              {{ $t('confirm.apply') }}
            </v-btn>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="11" cols="11">
            <v-btn @click="dialog = false" block color="error">
              {{ $t('confirm.cancel') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { en } from '@/lang/en-US.js';
import { es } from '@/lang/es-ES.js';
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    ...mapActions('i18n', ['write_en', 'write_es']),
    aplicarCambios() {
      this.write_en(en);
      this.write_es(es);
      localStorage.setItem('en', JSON.stringify(en));
      localStorage.setItem('es', JSON.stringify(es));
      this.dialog = false;
    },
  },
};
</script>
