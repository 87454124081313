<template>
  <div>
    <v-navigation-drawer v-model="isActive" temporary app>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <div class="navigationDrawer--title">
                <img src="@/assets/Iconos/Logo_ GROBE_.svg" height="50px" />
                <v-icon color="#E30707" @click="isActive= false">mdi-close-circle-outline</v-icon>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item>
          <v-list-item-content @click="localePathEx('home')">
            <v-btn color="#071C87" block text>
              {{ $t('navitationMenu.home') }}
              <v-spacer />
              <v-icon right dark large color="#DD0003">
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="localePathEx('about-us')">
          <v-list-item-content>
            <v-btn color="#071C87" block text>
              {{ $t('navitationMenu.aboutUs') }}
              <v-spacer />
              <v-icon right dark large color="#DD0003">
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="localePathEx('services')">
          <v-list-item-content>
            <v-btn color="#071C87" block text>
              {{ $t('navitationMenu.services') }}
              <v-spacer />
              <v-icon right dark large color="#DD0003">
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="localePathEx('coverage')">
          <v-list-item-content>
            <v-btn color="#071C87" block text>
              {{ $t('navitationMenu.coverage') }}
              <v-spacer />
              <v-icon right dark large color="#DD0003">
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <div class="text-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" color="#071C87" block text>
                    {{ $t('navitationMenu.contactUs') }}
                    <v-spacer />
                    <v-icon right dark large color="#DD0003">
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="goToContactSection('contact')">
                    <v-list-item-title>{{
                      $t('navitationMenu.contactUsQuote')
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="goToContactSection('join')">
                    <v-list-item-title>{{
                      $t('navitationMenu.contactUsCarrier')
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app elevate-on-scroll elevation="1" color="white" fixed>
      <img
        @click="localePathEx('home')"
        src="@/assets/Iconos/Logo_ GROBE_.svg"
        height="100%"
      />
      <v-spacer />
      <div class="d-none d-md-flex">
        <v-btn text color="black" @click="localePathEx('home')">{{
          $t('navitationMenu.home')
        }}</v-btn>
        <v-btn text color="black" @click="localePathEx('about-us')">{{
          $t('navitationMenu.aboutUs')
        }}</v-btn>
        <v-btn text color="black" @click="localePathEx('services')">
          {{ $t('navitationMenu.services') }}
        </v-btn>
        <v-btn text color="black" @click="localePathEx('coverage')">{{
          $t('navitationMenu.coverage')
        }}</v-btn>
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text color="black" v-bind="attrs" v-on="on">
                {{ $t('navitationMenu.contactUs') }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="goToContactSection('contact')">
                <v-list-item-title>{{
                  $t('navitationMenu.contactUsQuote')
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="goToContactSection('join')">
                <v-list-item-title>{{
                  $t('navitationMenu.contactUsCarrier')
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <aplicar v-if="verAplicar"/>
      </div>
      <v-spacer />
      <div class="d-none d-md-flex app-bar-icon-container">
        <div>
          <v-btn
            @click="localePathEx('contact-us')"
            icon
            dark
            small
            color="primary"
            ><v-icon>mdi-email</v-icon></v-btn
          >
        </div>
        <div>
          <!-- <v-btn
            @click="changeI18n('es')"
            :outlined="$i18n.locale !== 'es'"
            depressed
            dark
            small
            color="primary"
            class="mr-1"
            >ES</v-btn
          > -->
          <v-btn
            :outlined="$i18n.locale !== 'en'"
            depressed
            small
            dark
            color="primary"
            @click="changeI18n('en')"
            >EN</v-btn
          >
        </div>
      </div>
      <div class="d-md-none d-flex app-bar-icon-container-mini">
        <div style="display: flex;" class="pt-5">
            <!-- <p @click="changeI18n('es')" :class="$i18n.locale !== 'es' ? 'mr-2 inactivo':'mr-2 activo'">ES</p> -->
            <p @click="changeI18n('en')" :class="$i18n.locale !== 'en' ? 'inactivo':'activo'">ENG</p>
        </div>
        <div>
          <v-app-bar-nav-icon
            @click="isActive = true"
            class="d-flex"
            color="#00126A"
          />
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import Aplicar from '@/components/Aplicar.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    Aplicar,
  },
  methods: {
    localePathEx(name) {
      this.$router.push({name})
    },
    goToContactSection(sectionName) {
      this.$router.push({name: 'contact-us', query: { section: sectionName }})
    },
    changeI18n(idioma){
      this.$i18n.locale = idioma;
    },
  },
  data: () => ({
    isActive: false,
  }),
  computed: {
    ...mapGetters('login',['getActive']),
    verAplicar() {
      return this.getActive;
    }
  }
}
</script>

<style>
.app-bar-icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 36px;
}
.app-bar-icon-container-mini {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0px;
}

.navigationDrawer--title {
  display: flex;
  justify-content: space-between;
}
.activo {
  font-family: Crossten Soft;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */
  color: #000;
}
.inactivo {
  font-family: Crossten Soft;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */
  color: #C4C4C4;
}
</style>
