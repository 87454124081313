<template>
  <v-app>
    <app-edit :dialog="edit"/>
    <app-cookies/>
    <app-header @drawer="mostrar"/>
    <app-view/>
    <app-footer/>
  </v-app>
</template>
<script>
import appHeader from '@/layouts/AppToolbar.vue';
import appView from '@/layouts/default.vue';
import appFooter from '@/layouts/AppFooter.vue';
import appCookies from '@/layouts/AppCookies.vue';
import appEdit from '@/layouts/AppEdit.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    appHeader,
    appView,
    appFooter,
    appCookies,
    appEdit,
  },
  data() {
    return {
      drawer: false
    }
  },
  methods: {
    mostrar(val){
      this.drawer = val;
    }
  },
  computed: {
    ...mapGetters(['getEdit']),
    edit(){
      let edit = this.getEdit;
      return edit
    }
  }
}
</script>
