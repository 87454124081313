<template>
  <v-footer color="black">
    <v-container>
      <v-row>
        <v-col class="col-12 pb-0">
          <img src="@/assets/Iconos/Logo blanco_negro.svg" width="150" alt=""/>
        </v-col>
        <v-col class="col-12 col-md-3 white--text">
          <p class="mt-4" @click="editar('fotter.details')">
            {{$t('fotter.details')}}
          </p>
        </v-col>
        <v-col class="col-12 col-md-2 offset-md-3 offset-sm0">
          <h4 @click="editar('fotter.contact')" class="white--text">{{$t('fotter.contact')}}</h4>
          <div class="white--text d-flex align-center mt-4">
            <a href="tel:8673343939" class="call-to">
              <v-icon color="white">mdi-cellphone</v-icon>
              <p class="mb-0 ml-4">(867) 3343939</p>
            </a>
          </div>
        </v-col>
        <v-col class="col-12 col-md-4">
          <h4 class="white--text" @click="editar('fotter.locations')">{{$t('fotter.locations')}}</h4>
          <div class="white--text d-flex align-center mt-4">
            <v-icon color="white">mdi-map-marker</v-icon>
            <p @click="editar('fotter.direccion_one')" class="mb-0 ml-4">
              {{$t('fotter.direccion_one')}}
            </p>
          </div>
          <div class="white--text d-flex align-center mt-4">
            <v-icon color="white">mdi-map-marker</v-icon>
            <p @click="editar('fotter.direccion_two')" class="mb-0 ml-4">{{$t('fotter.direccion_two')}}</p>
          </div>
        </v-col>
      </v-row>
      <v-row class="my-10" justify="center">
        <v-col v-if="$vuetify.breakpoint.smAndDown" lg="4" md="4" sm="12" cols="12" class="offset-lg-2 offset-md-2" style="display: flex;">
            <img-credits/><p style="color: white; margin: 0 10px 0 10px;">|</p><privacy/>
        </v-col>
        <v-col lg="6" md="6" sm="12" cols="12">
          <h4 class="white--text font-weight-light" @click="editar('fotter.copyright')">
            {{$t('fotter.copyright')}}
          </h4>
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.smAndDown" lg="4" md="4" sm="12" cols="12" style="display: flex;" class="offset-lg-2 offset-md-2">
            <img-credits/><p style="color: white; margin: 0 10px 0 10px;">|</p><a @click="localePathEx('privacy')" style="color: #ffeb00;">{{$t('privacy.title')}}</a>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import ImgCredits from '@/components/ImgCredits.vue'
import Privacy from '@/components/Privacy.vue'

export default {
  components: {
    ImgCredits,
    Privacy,
  },
  methods: {
    localePathEx(name) {
      this.$router.push({name})
    },
  }
}
</script>

<style>
.call-to {
  display: flex;
  align-items: center;
  color: white !important;
  text-decoration: none;
}
</style>
