<template>
  <v-dialog v-model="dialog" scrollable persistent>
    <v-card>
      <v-card-title>
        {{$t('editor.title')}} <v-spacer></v-spacer
        ><v-icon @click="Cancelar()">mdi-close-circle</v-icon>
      </v-card-title>
      <v-card-subtitle> {{$t('editor.subtitle')}} </v-card-subtitle>
      <v-card-text>
        <v-row justify="center" align="center" class="mt-3 mb-0">
          <v-col xl="5" lg="6" md="6" sm="11" cols="12">
            <v-textarea
              readonly
              :value="original"
              hide-details="auto"
              :label="$t('editor.original')"
              outlined
              rows="7"
            ></v-textarea>
          </v-col>
          <v-col xl="5" lg="6" md="6" sm="11" cols="12">
            <v-textarea
              v-model="nuevo"
              hide-details="auto"
              :label="$t('editor.new')"
              outlined
              rows="7"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pb-6">
        <v-row justify="end">
          <v-col xl="2" lg="2" md="3" sm="12" cols="12">
            <v-btn @click="Aplicar(info, nuevo)" block color="success">
              {{$t('editor.apply')}}
            </v-btn>
          </v-col>
          <v-col xl="2" lg="2" md="3" sm="12" cols="12">
            <v-btn @click="Restaurar(original)" block class="info">
              {{$t('editor.load')}}
            </v-btn>
          </v-col>
          <v-col xl="2" lg="2" md="3" sm="12" cols="12">
            <v-btn @click="Cancelar()" block color="error"> {{$t('editor.cancel')}} </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapMutations } from 'vuex';
import { mapGetters } from 'vuex';
import { ActualizarEN, en } from '@/lang/en-US.js';
import { ActualizarES, es } from '@/lang/es-ES.js';

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //dialog: false,
      nuevo: '',
      original: '',
      detailsEdit: {
        lang: '',
        page: '',
        element: '',
        subElement: '',
        isArray: '',
        numArray: '',
        objArray: '',
        textOriginal: '',
      },
    };
  },
  methods: {
    ...mapMutations(['changeEdit']),
    async Aplicar(data, nuevo, original) {
      const n = nuevo.trim();
      if (n === original) {
        this.Cancelar();
      }
      switch (data.lang) {
        case 'es':
          await ActualizarES(data, n);
          this.Cancelar();
          break;
        case 'en':
          await ActualizarEN(data, n);
          this.Cancelar();
          break;
        default:
          this.Cancelar();
          break;
      }
    },
    Cancelar() {
      this.changeEdit();
    },
    Restaurar(original) {
      this.nuevo = original;
    },
    async cargarOriginal(data) {
      const lang = await JSON.parse(localStorage.getItem(data.lang));
      if (data.isArray) {
        if (data.numArray >= 0) {
          if (data.objArray) {
            if (data.page && data.element) {
              if (data.subElement) {
                this.original =
                  lang[data.page][data.element][data.subElement][data.numArray][
                    data.objArray
                  ];
              } else {
                this.original =
                  lang[data.page][data.element][data.numArray][data.objArray];
              }
            }
          } else {
            if (data.page && data.element) {
              if (data.subElement) {
                this.original =
                  lang[data.page][data.element][data.subElement][data.numArray];
              } else {
                this.original = lang[data.page][data.element][data.numArray];
              }
            }
          }
        }
      }
      if (data.page && data.element && !data.isArray) {
        if (data.subElement) {
          this.original = lang[data.page][data.element][data.subElement];
        } else {
          this.original = lang[data.page][data.element];
        }
      }
      return;
    },
    async cargarNuevoEspecial(data) {
      let lang;
      switch (data.lang) {
        case 'es':
          lang = es;
          break;
        case 'en':
          lang = en;
          break;
        default:
          lang = await JSON.parse(localStorage.getItem(data.lang));
          break;
      }
      if (data.isArray) {
        if (data.numArray >= 0) {
          if (data.objArray) {
            if (data.page && data.element) {
              if (data.subElement) {
                this.nuevo =
                  lang[data.page][data.element][data.subElement][data.numArray][
                    data.objArray
                  ];
              } else {
                this.nuevo =
                  lang[data.page][data.element][data.numArray][data.objArray];
              }
            }
          } else {
            if (data.page && data.element) {
              if (data.subElement) {
                this.nuevo =
                  lang[data.page][data.element][data.subElement][data.numArray];
              } else {
                this.nuevo = lang[data.page][data.element][data.numArray];
              }
            }
          }
        }
      }
      return;
    },
    cargarNuevo(data) {
      if (data.isArray) {
        this.cargarNuevoEspecial(data);
      } else {
        this.nuevo = this.$t(data.textOriginal);
      }
    },
  },
  computed: {
    ...mapGetters(['getEditData']),
    info() {
      const data = this.getEditData;
      this.cargarOriginal(data);
      this.cargarNuevo(data);
      this.detailsEdit = data;
      return data;
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        return;
      }
      this.info;
    },
  },
};
</script>
